import {PlatformLocation} from '@angular/common';
import {AfterContentChecked, Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {ConfirmationModalComponent} from '@components/confirm-modal/confirm-modal.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Select, Store} from '@ngxs/store';
import {DataLayerService, NEW_POP_ACTIONS} from '@services/data-layer.service';
import {UserService} from '@services/user.service';
import {Observable, Subject} from 'rxjs';
import {filter, take, takeUntil} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {BaseComponent} from './baseComponent';
import {LayoutService} from './core/services/layout/layout.service';
import {WebsiteconfigService} from './core/services/websiteconfig.service';
import {AssignTokens, SetRainDeliveryTme} from './core/store/actions/auth.actions';
import {AssignSlug} from './core/store/actions/routing.actions';
import {CoreState} from './core/store/state/core.state';
import {CACHE_AUTH_TOKEN, CACHE_CART, CACHE_IDM_TOKEN} from './models/constants';
import {AuthenticationService} from './services/auth.service';
import {CacheService} from './services/cache.service';
import {CartService} from './services/cart.service';
import {ElementService} from './services/element.service';
import {CartExpiryService, IdleService} from './services/idle.service';
import {TokenService} from './services/token.service';
import {GetAllOrders} from './store/actions/order.actions';
import {BlackFridayProductModel, ProductState} from './store/state/product.state';
import {register} from 'swiper/element/bundle';
import {Meta, Title} from '@angular/platform-browser';
import { ButtonClickedTracking, SetGClid, SetNavigationID, SetReferrer, SetSource } from './store/actions/rain-analytics-tracker.actions';
import { RainAnalyticsTrackerClick } from './store/interfaces/rain-analytics-tracker';
import { isEmpty } from 'lodash';
import { FetchRainFlags } from './core/store/actions/firebase.actions';
import { FetchClickToClaim } from './store/actions/click-to-claim.actions';
import { FirebaseConfigsState } from './core/store/state/firebase-configs.state';
import { RainFlags } from './core/interfaces/rain-flags.interface';
import { UserActivityService } from '@services/user-activity.service';
import { ExitPopComponent } from '@components/exit-pop/exit-pop.component';
import { AddVoucherTicketCreationTimer, GetVoucherTicketCreationTimerStatus } from '@components/sales-modal/store/actions/limit-ticket-creations.actions';
import { LimitSalesTicketCreationState } from '@components/sales-modal/store/state/limit-ticket-creation.state';
// TODO : jquery needs to be removed
declare var $: any;
const redirectToHome = ['/login', '/activate-SIM', '/activate-SIM/select-gotSIM'];
register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Select(ProductState.CatelogueIsLoaded) catelogueIsLoaded$: Observable<boolean>;
  @Select(LimitSalesTicketCreationState.isVoucherTicketCreatingAllowed) isVoucherTicketCreatingAllowed$: Observable<boolean>;
  private exitModalRef: NgbModalRef | null = null;
  previousUrl: string;
  isUserInactive = false;
  exitPopCached:boolean = false;
  isRootUrl: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private unsubscribeFeatureFlags$ = new Subject<void>();
  @Select(FirebaseConfigsState.getFlags) flags$: Observable<RainFlags>;
  blackFridayProductsmap: { [key: string]: BlackFridayProductModel } = {
    'f33cc102-cacb-491f-84b7-080fd3c7bd0b': {
      bf: '5g_upfront_premium_black_friday',
      original: 'standard_5g_upfront_v1',
      boostedFrom: 'unlimited home 5G standard',
      boostedTo: 'unlimited home 5G premium',
      originalPrice: 999
    },
    '6c7e7644-9a6d-4e8e-a541-520dbf7f0512': {
      bf: '5g_postpaid_premium_black_friday',
      original: '9',
      boostedFrom: 'unlimited home 5G standard',
      boostedTo: 'unlimited home 5G premium',
      originalPrice: 999
    },
    'cca0fe79-34d4-462b-aacd-20fc7da8cd85': {
      bf: '5g_upfront_standard_black_friday',
      original: 'basic_5g_upfront_v1',
      boostedFrom: 'unlimited home 5G basic',
      boostedTo: 'unlimited home 5G standard',
      originalPrice: 739
    },
    'b236d2f9-f4cb-4efc-847b-b780f9dcf2f3': {
      bf: '5g_postpaid_standard_black_friday',
      original: '11',
      boostedFrom: 'unlimited home 5G basic',
      boostedTo: 'unlimited home 5G standard',
      originalPrice: 739
    }
  };

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private idleTimeoutService: IdleService,
    private cartTimeoutService: CartExpiryService,
    private authService: AuthenticationService,
    private cartService: CartService,
    private elementService: ElementService,
    private cacheService: CacheService,
    private modalService: NgbModal,
    private platformLocation: PlatformLocation,
    private tokenService: TokenService,
    public websiteConfig: WebsiteconfigService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private store: Store,
    private layoutService: LayoutService,
    private titleService: Title,
    private meta: Meta,
    private aRoute: ActivatedRoute,
    private userActivityService:UserActivityService
  ) {
    super();
    console.log(`--- version: ${environment.version} ---`);
    window.addEventListener('scroll', e => {
      const scrollPos = document.body.getBoundingClientRect().top;
      const nav = document.getElementById('header-menu');
      const currentRouter = this.router.url;

      if (currentRouter === '/' || currentRouter === '/home') {
        if (scrollPos === 0) {
          return nav.classList.remove('mobile-header');
        }
        if (scrollPos < 100) {
          nav.classList.add('mobile-header');
        } else {
          nav.classList.remove('mobile-header');
        }
      } else {
        if (scrollPos < 80) {
          nav.classList.add('mobile-header');
        } else {
          nav.classList.remove('mobile-header');
        }
      }
    });

    this.platformLocation.onPopState(() => this.modalService.dismissAll());

    this.cartTimeoutService.onExpired.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.cartService.clear();
    });

    this.tokenService.onExpired.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.signOut();
    });

    this.idleTimeoutService.onExpired.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.signOut();
    });

    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(event => {
      let prevUrl: string;
      if (event instanceof NavigationStart) {
        const { id } = event;
        prevUrl = window.location.pathname;
        if (id > 1) {
          this.store.dispatch(new SetReferrer(prevUrl));
        }
      }
      if (event instanceof NavigationEnd) {
        const { id, url } = event;

        switch (url) {
          case '/home':
            this.titleService.setTitle('rainOne - Unlimited 5G Internet & Mobile SIMs for Home & Business');
            this.meta.addTags([
              {
                name: 'description',
                content:
                  'Experience lightning-fast 5G home internet with rainOne - unlimited data, free router, and mobile SIMs included. Enjoy seamless connectivity for your entire household.'
              },
              { name: 'keywords', content: 'rainOne, rain 5G, Unlimited 5G internet, rain101 router, 5G smart router, rain WiFi plans' }
            ]);
            break;
          case '/work':
            this.titleService.setTitle('rainOne - Unlimited 5G Internet & Mobile SIMs for Home & Business');
            this.meta.addTags([
              {
                name: 'description',
                content:
                  "Unlock the power of 5G for your business with rainOne work - unlimited internet and mobile lines for SMEs. Boost productivity with rain's reliable network."
              },
              { name: 'keywords', content: 'rainOne work, rain 5G, Business internet solutions, SME internet solutions, Unlimited 5G internet' }
            ]);
            break;
          case '/mobile':
            this.titleService.setTitle('rainOne - Unlimited 5G Internet & Mobile SIMs for Home & Business');
            this.meta.addTags([
              {
                name: 'description',
                content:
                  'Affordable 4G mobile plans from rain - flexible data, minutes, and SMS bundles to suit your needs. Enjoy HD voice calls and seamless connectivity.'
              },
              { name: 'keywords', content: 'rain mobile, 4G mobile plans, Mobile data bundles, VoLTE calls, rainTalk app, HD voice calls' }
            ]);
            break;

          default:
            this.titleService.setTitle('rain | 4G & 5G network');
            this.meta.addTags([
              {
                name: 'description',
                content:
                  "rain - South Africa's leading 5G provider offering innovative home, work, and mobile solutions. Experience lightning-fast internet with rainOne and affordable 4G mobile plans."
              },
              {
                name: 'keywords',
                content: 'rainOne, rain 5G, rain mobile, rain101 router, Unlimited 5G internet, 4G mobile plans, South African mobile network'
              }
            ]);
            break;
        }

        const pageView: RainAnalyticsTrackerClick = {
          buttonId: `n/a`,
          buttonLabel: `n/a`,
          event: 'page_view'
        };
        this.store.dispatch(new SetNavigationID(id));
        this.store.dispatch(new ButtonClickedTracking(pageView));
      }

      //   if (event instanceof NavigationStart) {
      //     if (isNil(this.previousUrl) == false) {
      //       this.renderer.removeClass(document.body, this.previousUrl);
      //     } else {
      //       if (includes(redirectToHome, event.url)) {
      //         this.previousUrl = 'none';
      //         this.router.navigate(['/']);
      //         return;
      //       }
      //       this.modalService.dismissAll();
      //     }
      //
      //     const currentUrlSlug = event.url.slice(1);
      //     const revisedSlug =
      //       isEmpty(currentUrlSlug) ||
      //       currentUrlSlug.includes('?') ||
      //       currentUrlSlug === '' ||
      //       currentUrlSlug === 'more-about-4g' ||
      //       currentUrlSlug === 'e-sim' ||
      //       currentUrlSlug === 'fiveg' ||
      //       currentUrlSlug === 'activate-SIM' ||
      //       currentUrlSlug === 'find-SIM' ||
      //       currentUrlSlug === 'support' ||
      //       currentUrlSlug === 'rain-maker' ||
      //       currentUrlSlug === 'select-gotSIM' ||
      //       currentUrlSlug === 'about-my-rain' ||
      //       currentUrlSlug.includes('register')
      //         ? 'onscroll'
      //         : 'none';
      //
      //     this.renderer.addClass(document.body, revisedSlug);
      //
      //     this.renderer.removeClass(document.body, 'white');
      //
      //     this.renderer.removeClass(document.body, 'support');
      //
      //     if (currentUrlSlug !== '') {
      //       this.renderer.removeClass(document.body, 'home');
      //     }
      //
      //     this.previousUrl = revisedSlug;
      //   }
    });

    // Removed to make shit load faster
    // this.store.dispatch([
    //   new FetchProductsFromCatelogue(),
    //   new SetBlackFridayProducts(this.blackFridayProductsmap),
    //   new FetchLoadSheddingFallbackCopy(),
    //   new FetchRainFlags(),
    //   new FetchGiveAGigPromoConfig(),
    //   new FetchTopupConfig(),
    //   new FetchBlackFridayPromoConfig(),
    //   new FetchNvidiaActivated(),
    //   new InitDeviceChecker(),
    //   new InitAdBlockerChecker(),
    //   new GetBillCycleOptions(),
    //   new FetchDeliveryWaitTime(),
    //   new GetAllProducts(),
    //   new FetchRicaStatusCopy(),
    //   new FetchPromos(),
    //   new FetchDefaultSkins()
    // ]);
    this.store.dispatch(new FetchRainFlags());
    this.layoutService
      .subscribeToLayoutChanges()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  private signOut() {
    this.authService.signOut();

    $('.modal').modal('hide');

    this.elementService.scrollToHome();
  }

  ngOnInit() {
    // update page title and description
    const pageTitle = '5G & 4G network provider | rain';
    this.titleService.setTitle(pageTitle);
    this.meta.updateTag({ property: 'og:title', content: pageTitle });
    const metaDescription =
      'Keep your work & home connected with unlimited 5G wifi +FREE calls & data for your phones. Change to rainOne from R625pm. No contracts. Ts & Cs apply.';
    this.meta.updateTag({ name: 'description', content: metaDescription });
    this.meta.updateTag({ property: 'og:description', content: metaDescription });

    this.getSiteConfig();
    this.listenToGlobalSignout();
    // this.getGlobalNotifications();
    this.getDeliveryWaitTime();
    if (this.authService.isSignedIn === false) {
      this.cacheService.clear([CACHE_CART]);
    }
    this.pageViewEvent();
    
    if (this.authService.isSignedIn) {
      this.store.dispatch(new GetAllOrders());
    }

    this.aRoute.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((res) => {

        if(isEmpty(res)) {
            this.store.dispatch(new SetReferrer(window.location.pathname));
        }
      const { utm_source, gad_source, gclid } = res;

      if(utm_source) {
        this.store.dispatch([new SetReferrer(utm_source), new SetSource(utm_source)]);
      }

      if(gad_source) {
        this.store.dispatch([new SetReferrer('google.com'), new SetSource('google.com')]);
      }
      if(gclid) {
        this.store.dispatch([new SetGClid(gclid)]);
      }
    });

      this.store.dispatch(new FetchClickToClaim());

      this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd), 
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.checkUrl(); 
        if (!this.authService.isSignedIn && this.isRootUrl) {
          this.checkFeatureFlags();
        } else {
          this.unsubscribeFeatureFlags$.next(); 
        }
      });
    
    }

    private checkUrl(): void {
      const currentUrl = this.router.url;
      this.isRootUrl = currentUrl === '/'; 
    }

 public checkFeatureFlags() {
  this.unsubscribeFeatureFlags$.next();
      this.flags$.pipe(takeUntil(this.unsubscribeFeatureFlags$)).subscribe({
        next: (res) => {
          if (res) {
            if (res?.exit_pop_promo && res?.exit_pop_timer) {
              this.userActivityService.setInactivityTime(res.exit_pop_timer);
              this.store.dispatch(new GetVoucherTicketCreationTimerStatus());
              this.isVoucherTicketCreatingAllowed$.subscribe(value => {
                this.exitPopCached = value;
              if(value === false && this.isRootUrl){
                this.userActivityService.userInactive$
                  .pipe(
                    take(1), 
                    takeUntil(this.unsubscribeFeatureFlags$)
                  )
                  .subscribe({
                    next: (isInactive) => {
                      this.isUserInactive = isInactive;
                      if (isInactive) {
                        this.showExitPop();
                        this.dataLayerService.handleNewPopupTriggers(NEW_POP_ACTIONS.show);
                        this.store.dispatch(new AddVoucherTicketCreationTimer(24));
                      }
                    }
                  });
              }
            });
            }
          }
        },
      });
    }

    public showExitPop(): void {
      if (this.exitModalRef ||  this.exitPopCached  ) {
        return;
      }
      const options = { size: 'lg' as any, windowClass: 'slideInUp', centered: true };
      this.exitModalRef = this.modalService.open(ExitPopComponent, options);
      this.exitModalRef.result
        .then(() => {
          this.exitModalRef = null;
        })
        .catch(() => {
          this.exitModalRef = null;
        });
    }

  public ngAfterContentChecked(): void {
    this.onAppRefresh();
  }

    pageViewEvent() {
        // Track page view for analytics -- starts
        const pageTitles = {
            // Static routes mapped to page titles
            '/': 'Home',
            '/activate-SIM': 'Activate SIM',
            '/register': 'create an account or sign in',
            '/register/activate': 'Register - Activate',
            '/about-my-rain': 'About My Rain',
            '/more-about-4g': '4g: The sim that saves',
            '/fiveg': '5g: fast internet delivered fast',
            '/login': 'Login',
            '/careers': 'Careers: we believe in diversity',
            '/legal': 'Legal',
            '/my-rain': 'My rain',
            '/my-account': 'My Account',
            '/change-password': 'My Account - Change Password',
            '/payment-details': 'Payment Details',
            '/mobile-terms-and-conditions': 'Mobile Terms and Conditions',
            '/rain-maker': 'Rain Maker',
            '/rain-maker/register': 'Rain Maker: Register',
            '/rain-maker/register/success': 'Rain Maker: Register: Success',
            '/arrears-payment-status': 'Payment Status: Arrears',
            '/payment-status': 'Payment Status',
            '/pay-now-payment-status': 'Payment Status: Pay Now',
            '/cart': 'My Cart',
            '/coverage': 'Check Coverage',
            '/promo-terms': 'Promotional T&C-black Friday promo',
            '/notifications': 'Notification-preference',
            '/purchase-wizard': 'Purchase Wizard',
            '/skin-selector': 'Purchase Journey - skin selection',
            '/speed-up': 'Purchase Journey - wifi speed selection',
            '/manage-geforce': 'Manage geforce',
            '/4Gsetup': '4G mobile set up',
            '/4Gmobile': '4G mobile',
            '/our-network': 'Our Network',
            '/mnp/port-out/validate': 'OTP verification',
            '/port-in': 'Port my number',
            '/level-up': 'Purchase Journey - mobile level selection',
            '/nvidia': 'Nvidia - landing',
            '/nvidia/games-list': 'Nvidia - games list',
            '/nvidia/download': 'Nvidia - download',
            '/nvidia/faq': 'Nvidia - FAQ',
        };

    // Handling for dynamic routes
    // const urlFragments = this.router.routerState.snapshot.url.replace('/', '').split('/');
    //
    // switch (urlFragments[0]) {
    //   case 'service':
    //     const serviceId = urlFragments[1];
    //     if (serviceId) {
    //       this.dataLayerService.pageView(
    //         this.router.routerState.snapshot.url,
    //         `Service - ${serviceId}`,
    //         !this.authService.isSignedIn ? null : this.userService.getUserId()
    //       );
    //     }
    //     break;
    //
    //   default:
    //     if (pageTitles[this.router.routerState.snapshot.url]) {
    //       this.dataLayerService.pageView(
    //         this.router.routerState.snapshot.url,
    //         pageTitles[this.router.routerState.snapshot.url],
    //         !this.authService.isSignedIn ? null : this.userService.getUserId()
    //       );
    //     }
    //     break;
    // }
    // Track page view for analytics -- ends
  }

  getSiteConfig() {
    this.websiteConfig.setGlobalConfigs().then(
      res => {
        if (res) {
        }
      },
      error => {
        console.log('ERROR with site config subscription ' + error);
      }
    );
  }

  getDeliveryWaitTime() {
    this.websiteConfig
      .getDeliveryDelay()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((deliveryTime: any) => {
        if (deliveryTime) {
          this.store.dispatch(new SetRainDeliveryTme(deliveryTime?.days));
        }
      });
  }

  listenToGlobalSignout() {
    this.websiteConfig
      .listenToGlobalSignout()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res?.signout) {
            this.authService.signOut();
            this.router.navigateByUrl('/');
          }
        },
        error => {
          console.log('ERROR with global signout subscription ' + error);
        }
      );
  }

  getGlobalNotifications() {
    this.websiteConfig
      .getGlobalNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (localStorage.getItem('showedGlobalMessage') !== 'true' && res && res.show) {
            this.showAlert(res);
          }

          if (localStorage.getItem('showedGlobalMessage') === 'true' && res && !res.show) {
            localStorage.removeItem('showedGlobalMessage');
          }
        },
        error => {
          console.log('ERROR with global notifications subscription ' + error);
        }
      );
  }

  showAlert(res) {
    const data = {
      title: res.message.title,
      description: res.message.description,
      buttonName: res.button
    };
    const alertMsg = this.modalService.open(ConfirmationModalComponent, {
      size: <any>'confirm',
      windowClass: 'slideInUp',
      centered: true
    });
    alertMsg.componentInstance.data = data;
    localStorage.setItem('showedGlobalMessage', 'true');
  }

  private onAppRefresh() {
    const isAuthed = this.store.selectSnapshot(CoreState.isAuthenticated);
    const hasTokens = this.store.selectSnapshot(CoreState.hasTokensLoaded);

    if (isAuthed && !hasTokens) {
      const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
      const authToken = this.tokenService.get(CACHE_AUTH_TOKEN);

      if (idmToken && authToken) {
        return this.store.dispatch(new AssignTokens({ idm: idmToken, auth: authToken }));
      }
    }
  }

  private getSlug(url: string) {
    return this.store.dispatch(new AssignSlug(url));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeFeatureFlags$.next(); 
    this.unsubscribeFeatureFlags$.complete();
  }
}
