import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TokenService} from "@services/token.service";
import {ConfigService} from "@services/config.service";
import {Observable} from "rxjs";
import {CACHE_AUTH_TOKEN, CACHE_IDM_TOKEN} from "@models/constants";
import {catchError, map, timeout} from "rxjs/operators";
import {PaymentStatusObject} from "../../../core/store/state/billing.state";
import {Result} from "@models/result";
import {UIState} from "../../../shared/store/state/ui.state";
import {AuthState} from "../../../core/store/state/auth.state";
import {Store} from "@ngxs/store";
import {IPaymentDetail, PaymentDetail} from "@models/paymentDetail";
import {ServiceHelper} from "@services/serviceHelper";

@Injectable({
    providedIn: 'root'
})
export class BillingServiceV2 {
    constructor(
        private http: HttpClient,
        private store: Store,
        private tokenService: TokenService,
        private configService: ConfigService,
    ) {
    }

    getPaymentDate(): Observable<any> {
        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
        const requestPath = `${this.configService.AXIOM_IDM_URL}/recurringpaymentdate`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idmToken
            })
        };
        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((res: any) => {
                return res;
            })
        );
    }

    public GetLastFailedPayment(userId: string): Observable<PaymentStatusObject | Result<unknown>> {

        const idmToken = this.tokenService.get(CACHE_IDM_TOKEN);
        const requestPath = `${this.configService.BASE_API_URL}/v1/bss-pg/payments/failed/${userId}`;

        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: 'Bearer ' + idmToken
            })
        };

        return this.http.get<PaymentStatusObject>(requestPath, httpOptions)
    }

    public getInvoices() {
        const requestPath = `${this.configService.API_URL}/billing/bill/`;

        let userMode = this.store.selectSnapshot(UIState.GetUIMode) ?? "consumer"
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: userToken
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    public getPromiseToPay() {
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-plans/promise-to-pay`;

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const tokens = this.store.selectSnapshot(AuthState.getTokens);
        let tokenCredential = tokens.tokenCredential;

        const domain = window.location.host;
        // const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // apiKey: apiKey,
                Authorization: 'Bearer ' + tokenCredential
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    public getPromiseToPayPayments(date: string) {
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-plans/promise-to-pay/payments?startDate=${date}`;

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const tokens = this.store.selectSnapshot(AuthState.getTokens);
        let tokenCredential = tokens.tokenCredential;

        const domain = window.location.host;
        // const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // apiKey: apiKey,
                Authorization: 'Bearer ' + tokenCredential
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    doPromisePayPayment(amount: string) {

        let channel = "paymentplan";

        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-management/checkout`;

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const tokens = this.store.selectSnapshot(AuthState.getTokens);
        let tokenCredential = tokens.tokenCredential;

        const domain = window.location.host;
        // const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // apiKey: apiKey,
                Authorization: tokenCredential
            })
        };

        let body = {
            channel, amount
        }

        return this.http.post(requestPath, body, httpOptions);
    }

    public getSubscriptions(accountNumber: string) {
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-plans/subscription-account/${accountNumber}`;

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const tokens = this.store.selectSnapshot(AuthState.getTokens);
        let tokenCredential = tokens.tokenCredential;

        const domain = window.location.host;
        // const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // apiKey: apiKey,
                Authorization: tokenCredential
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    public getSubscriptionPayments(date: string) {
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-plans/subscription/payments?startTime=${date}`;

        let userMode = this.store.selectSnapshot(UIState.GetUIMode)
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken)

        let userToken = ((userMode !== 'consumer')) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);
        const tokens = this.store.selectSnapshot(AuthState.getTokens);
        let tokenCredential = tokens.tokenCredential;

        const domain = window.location.host;
        // const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // apiKey: apiKey,
                Authorization: tokenCredential
            })
        };

        return this.http.get(requestPath, httpOptions);
    }

    getActivePaymentMethod() {
        let userMode = this.store.selectSnapshot(UIState.GetUIMode);
        let smeToken = this.store.selectSnapshot(AuthState.getSmeToken);

        let userToken = ((userMode !== 'consumer' && smeToken)) ? smeToken : this.tokenService.get(CACHE_AUTH_TOKEN);

        const requestPath = `${this.configService.API_URL}/billing/payment_method`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'LrQ2oFL4NNo9jgXdOey7DGjuQoyd3xpH';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: userToken
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                const payDetail = PaymentDetail.adapt(result);

                if (!payDetail.id) {
                    return Result.notFound<IPaymentDetail>();
                }

                return Result.success(payDetail);
            }),
            catchError(result => ServiceHelper.handleError<IPaymentDetail>(result))
        );
    }

    // public getPaymentCheckoutId(amount: number, paymentType: "ptp"
    // ): Observable<Result<CheckoutResponse>> {
    //     if (this.authService.isSignedIn === false) {
    //         return of(Result.error('Not Signed In'));
    //     }
    //
    //     const requestPath = `${this.configService.BASE_API_URL}/v1/bss-pg/payments/checkout`;
    //
    //     const domain = window.location.host;
    //     const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'qidSQZHGqFTShfuCksIZJ74aDwLpWEw5';
    //
    //     let returnBaseURL = '';
    //     if (domain.includes('sit')) {
    //         returnBaseURL = 'https://sit.precipitation.co.za';
    //     } else if (domain.includes('precipitation')) {
    //         returnBaseURL = 'https://precipitation.co.za';
    //     } else {
    //         returnBaseURL = 'https://www.rain.co.za';
    //     }
    //
    //     let request = {
    //         paymentType: paymentType,
    //         amount,
    //         successUrl: `${returnBaseURL}/arrears-payment-status?somedata=true`,
    //         failedUrl: `${returnBaseURL}/arrears-payment-status?otherdata=true`,
    //         cancelledUrl: `${returnBaseURL}/arrears-payment-status?nodata=true`
    //     };
    //
    //     const httpOptions = {
    //         headers: new HttpHeaders({
    //             'Content-Type': 'application/json',
    //             apiKey: apiKey,
    //             Authorization: this.tokenService.getTokenForAccountType()
    //         })
    //     };
    //
    //     return this.http.post(requestPath, request, httpOptions).pipe(
    //         timeout(this.configService.API_TIMEOUT),
    //         map((result: any) => {
    //             return Result.success(result);
    //         }),
    //         catchError(result => {
    //             return ServiceHelper.handleError<CheckoutResponse>(result);
    //         })
    //     );
    // }

    getPTPOptions() {
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-plans/promise-to-pay/options`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'qidSQZHGqFTShfuCksIZJ74aDwLpWEw5';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: this.store.selectSnapshot(AuthState.getTokenCredential)
            })
        };

        return this.http.get(requestPath, httpOptions).pipe(
            timeout(this.configService.API_TIMEOUT),
            map((result: any) => {
                return result
            })
        );
    }

    createPTP(paymentObject: any) {
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-plans/promise-to-pay`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'qidSQZHGqFTShfuCksIZJ74aDwLpWEw5';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: this.store.selectSnapshot(AuthState.getTokenCredential)
            })
        };

        return this.http.post(requestPath, paymentObject, httpOptions);
    }

    getExistingPTPs() {
        const requestPath = `https://prod-bss-api.rain.co.za/axiom/rain-go/payment-plans/promise-to-pay`;
        const domain = window.location.host;
        const apiKey = domain.includes('sit') ? '1rQWEBw1pUlsfHz2kISkqFBlm6nlvBKZ' : 'qidSQZHGqFTShfuCksIZJ74aDwLpWEw5';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                apiKey: apiKey,
                Authorization: this.store.selectSnapshot(AuthState.getTokenCredential)
            })
        };

        return this.http.get(requestPath, httpOptions);
    }
}