import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {tap} from "rxjs/operators";
import {
    ClearBillingState,
    GetActivePaymentMethod,
    GetFailedPayments,
    GetInvoices,
    GetPaymentDate,
    GetPromiseToPay,
    GetPromiseToPayMinimumValuesApi,
    GetPromiseToPayPayments,
    GetSubscriptionPayments,
    GetSubscriptions,
    SetPaymentDate,
    SetPromiseToPayDate,
    SetPromiseToPayFrequency
} from "../actions/billing.action";
import {BillingServiceV2} from "../service/billing.service";
import {PaymentStatusObject} from "../../../core/store/state/billing.state";
import {PaymentFrequency} from "../../pages/promise-to-pay/promise-to-pay.component";

export interface BillingStateModel {
    promiseToPay: {};
    promiseToPayPayments: {};
    promiseToPayPaymentDate: number;
    promiseToPayMinimumValues: {};
    promiseToPayPaymentFrequency: PaymentFrequency;
    subscriptions: {};
    subscriptionsPayments: {};
    paymentDate: {};
    paymentMethod: {};
    failedPayment: {};
    invoices: [];
}

@State<BillingStateModel>({
    name: 'billingV2',
    defaults: {
        subscriptions: null,
        subscriptionsPayments: null,
        promiseToPay: null,
        promiseToPayPayments: null,
        promiseToPayPaymentDate: null,
        promiseToPayPaymentFrequency: null,
        promiseToPayMinimumValues: null,
        paymentDate: null,
        paymentMethod: null,
        failedPayment: null,
        invoices: null,
    }
})
@Injectable({
    providedIn: 'root'
})
export class BillingStateV2 {
    constructor(
        private billingService: BillingServiceV2,
    ) {
    }

    @Selector()
    static getPaymentDate(state: BillingStateModel) {
        return state.paymentDate;
    }

    @Selector()
    static getPaymentMethod(state: BillingStateModel) {
        return state.paymentMethod;
    }

    @Selector()
    static getInvoices(state: BillingStateModel) {
        return state.invoices;
    }

    @Selector()
    static getPromiseToPay(state: BillingStateModel) {
        return state.promiseToPay;
    }

    @Selector()
    static getPromiseToPayPayments(state: BillingStateModel) {
        return state.promiseToPayPayments;
    }

    @Selector()
    static getSubscriptions(state: BillingStateModel) {
        return state.subscriptions;
    }

    @Selector()
    static getSubscriptionsPayments(state: BillingStateModel) {
        return state.subscriptionsPayments;
    }

    @Selector()
    static getPromiseToPayPaymentDate(state: BillingStateModel) {
        return state.promiseToPayPaymentDate;
    }

    @Selector()
    static getPromiseToPayPaymentFrequency(state: BillingStateModel) {
        return state.promiseToPayPaymentFrequency;
    }

    @Selector()
    static getPromiseToPayMinimumValues(state: BillingStateModel) {
        return state.promiseToPayMinimumValues;
    }

    @Action(GetPaymentDate)
    getPaymentDate(ctx: StateContext<BillingStateModel>, action: GetPaymentDate) {
        return this.billingService.getPaymentDate().pipe(
            tap((response: any) => {
                ctx.patchState({
                    paymentDate: response
                });
            })
        );
    }

    @Action(SetPromiseToPayDate)
    setPromiseToPayDate(ctx: StateContext<BillingStateModel>, action: SetPromiseToPayDate) {
        return ctx.patchState({
            promiseToPayPaymentDate: action.date
        });
    }

    @Action(SetPromiseToPayFrequency)
    setPromiseToPayFrequency(ctx: StateContext<BillingStateModel>, action: SetPromiseToPayFrequency) {
        return ctx.patchState({
            promiseToPayPaymentFrequency: action.frequency
        });
    }

    @Action(GetPromiseToPayMinimumValuesApi)
    getPromiseToPayMinimumValues(ctx: StateContext<BillingStateModel>, action: GetPromiseToPayMinimumValuesApi) {
        return this.billingService.getPTPOptions().pipe(
            tap((response: any) => {
                ctx.patchState({
                    promiseToPayMinimumValues: response
                });
            })
        );
    }

    @Action(SetPaymentDate)
    setPaymentDate(ctx: StateContext<BillingStateModel>, action: SetPaymentDate) {
        return ctx.patchState({
            paymentDate: action.date
        });
    }

    @Action(GetActivePaymentMethod)
    setActivePaymentMethod(ctx: StateContext<BillingStateModel>, action: GetActivePaymentMethod) {
        return this.billingService.getActivePaymentMethod().pipe(
            tap({
                next: (response) => {
                    ctx.patchState({paymentMethod: response?.value});
                }
            })
        );
    }

    @Action(GetInvoices)
    getInvoices(ctx: StateContext<BillingStateModel>, action: GetInvoices) {
        return this.billingService.getInvoices().pipe(
            tap((response: any) => {
                ctx.patchState({
                    invoices: response
                });
            })
        );
    }

    @Action(GetPromiseToPay)
    getPromiseToPay(ctx: StateContext<BillingStateModel>, action: GetPromiseToPay) {
        return this.billingService.getPromiseToPay().pipe(
            tap((response: any) => {
                ctx.patchState({
                    promiseToPay: response
                });
            })
        );
    }

    @Action(GetPromiseToPayPayments)
    getPromiseToPayPayments(ctx: StateContext<BillingStateModel>, action: GetPromiseToPayPayments) {
        return this.billingService.getPromiseToPayPayments(action.date).pipe(
            tap((response: any) => {
                ctx.patchState({
                    promiseToPayPayments: response
                });
            })
        );
    }

    @Action(GetSubscriptions)
    getSubscriptions(ctx: StateContext<BillingStateModel>, action: GetSubscriptions) {
        return this.billingService.getSubscriptions(action.accountNumber).pipe(
            tap((response: any) => {
                ctx.patchState({
                    subscriptions: response
                });
            })
        );
    }

    @Action(GetSubscriptionPayments)
    getSubscriptionsPayments(ctx: StateContext<BillingStateModel>, action: GetSubscriptionPayments) {
        return this.billingService.getSubscriptionPayments(action.date).pipe(
            tap((response: any) => {
                ctx.patchState({
                    subscriptionsPayments: response
                });
            })
        );
    }

    @Action(GetFailedPayments)
    GetLastFailedPayment(ctx: StateContext<BillingStateModel>, action: GetFailedPayments) {
        return this.billingService.GetLastFailedPayment(action.userId).pipe(
            tap({
                next: (res: PaymentStatusObject) => {
                    return ctx.patchState({
                        failedPayment: res?.data?.status ? res : null
                    });
                },
                error: (e: unknown) => {
                    return ctx.patchState({
                        failedPayment: null
                    })
                }
            }));
    }

    @Action(ClearBillingState)
    clearBillingState(ctx: StateContext<BillingStateModel>) {
        return ctx.setState({
            subscriptions: null,
            subscriptionsPayments: null,
            promiseToPayPayments: null,
            promiseToPayPaymentDate: null,
            promiseToPayPaymentFrequency: null,
            promiseToPayMinimumValues: null,
            promiseToPay: null,
            paymentDate: null,
            paymentMethod: null,
            failedPayment: null,
            invoices: null,
        });
    }
}
