import {Action, Selector, State, StateContext} from "@ngxs/store";
import {ClickToClaimService} from "@services/click-to-claim.service";
import {FetchClickToClaim} from "../actions/click-to-claim.actions";
import {ClickToClaimModel, HttpClickToClaimModel} from "../interfaces/click-to- claim.interface";
import {tap} from "rxjs/operators";
import {Injectable} from "@angular/core";

@State<ClickToClaimModel>({
    name: 'ClickToClaim',
    defaults: {
        sme: 0,
        consumer: 0,
        mobile: 0,
        exitvoucher:0
    }
})
@Injectable({
    providedIn: 'root'
})

export class ClickToClaimState {

    constructor(private clickToClaimService: ClickToClaimService) {
    }


    @Selector()
    static getClickToClaimValues(state: ClickToClaimModel) {
        return state;
    }

    @Action(FetchClickToClaim)
    nvm(ctx: StateContext<ClickToClaimModel>) {
        return this.clickToClaimService.fetchVoucherWebValues().pipe(
            tap({
                next: (response: HttpClickToClaimModel) => {
                    const result = response?.result;
                    
                    ctx.patchState({
                        sme: result?.WORK,
                        consumer: result?.HOME,
                        mobile: result?.MOBILE,
                        exitvoucher: result?.EXITVOUCHER
                    });
                },
                error: () => {
                    const res = {"WORK":150,"HOME":150,"MOBILE":0,"EXITVOUCHER":0}
                    ctx.patchState({
                        sme: res?.WORK,
                        consumer: res?.HOME,
                        mobile: res?.MOBILE,
                        exitvoucher:res?.EXITVOUCHER
                    });
                }
            })
        );
    }
}