import {Utils} from "@Utils";
import {PaymentFrequency} from "../../pages/promise-to-pay/promise-to-pay.component";

export class GetPaymentDate {
    static type = Utils.Helpers.Type('[PAYMENTDATE] GetDetails');
    constructor() { }
}

export class SetPaymentDate {
    static type = Utils.Helpers.Type('[PAYMENTDATE] Set Payment Date');
    constructor(public date: any) { }
}

export class GetFailedPayments {
    static type = Utils.Helpers.Type('[PAYMENTS] GetFailedPayments');
    constructor(public userId: string) { }
}

export class GetInvoices {
    static type = Utils.Helpers.Type('[INVOICES] GetAll');
    constructor() { }
}

export class GetPromiseToPay {
    static type = Utils.Helpers.Type('[PROMISE TO PAY] GetAll');
    constructor() { }
}

export class GetPromiseToPayMinimumValuesApi {
    static readonly type = Utils.Helpers.Type('[Promise To Pay] Get promise to pay minimum values api');
    constructor() {}
}

export class GetPromiseToPayMinimumValues {
    static readonly type = Utils.Helpers.Type('[Promise To Pay] Get promise to pay minimum values');
    constructor() {}
}

export class SetPromiseToPayDate {
    static readonly type = Utils.Helpers.Type('[Promise To Pay] Set pay date');
    constructor(public readonly date: number) {}
}

export class SetPromiseToPayFrequency {
    static readonly type = Utils.Helpers.Type('[Promise To Pay] Set frequency');
    constructor(public readonly frequency: PaymentFrequency) {}
}

export class GetPromiseToPayPayments {
    static type = Utils.Helpers.Type('[PROMISE TO PAY] GetAll payments');
    constructor(public date: string) { }
}

export class GetSubscriptions {
    static type = Utils.Helpers.Type('[SUBSCRIPTIONS] GetAll');
    constructor(public accountNumber: string) { }
}

export class GetSubscriptionPayments {
    static type = Utils.Helpers.Type('[SUBSCRIPTIONS] GetAll Payments');
    constructor(public date: string) { }
}

export class GetActivePaymentMethod {
    static type = Utils.Helpers.Type('[PAYMENT METHOD] Get Method');
    constructor() { }
}

export class ClearBillingState {
    static type = Utils.Helpers.Type('[BILLING] Clear State');
    constructor() { }
}